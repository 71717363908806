/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/select2@4.0.3/dist/css/select2.min.css
 * - /npm/datatables.net-dt@1.10.16/css/jquery.dataTables.css
 * - /npm/datatables.net-bs@1.10.16/css/dataTables.bootstrap.css
 * - /npm/datatables.net-responsive-dt@2.2.0/css/responsive.dataTables.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
